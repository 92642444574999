.mypaths {
  width: 100%;
  height: 100%;

  .mypaths-menu {
    width: 100%;
    height: 4rem;
    padding: 12px 30px;
    display: flex;
    gap: 2.5vw;
    border-bottom: 0.5px solid rgb(229, 229, 229);

    .each-mypath-menu {
      padding: 10px 30px;
      border-radius: 35px;
      font-weight: 400;
      font-size: 15px;
      color: #1f304f;
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .mypaths-content {
    width: 100%;
    height: calc(100% - 4rem);

    .mypathsNav {
      width: 100%;
      height: 2.5rem;
      border-bottom: 0.5px solid #e5e5e5;
      display: flex;
      align-items: center;

      .mypaths-name-div {
        width: 30%;
        display: flex;
        align-items: end;
        padding-left: 35px;
        font-weight: 400;
        font-size: 0.9rem;
      }

      .mypaths-description-div {
        width: 50%;
        display: flex;
        align-items: end;
        padding-left: 35px;
        font-weight: 400;
        font-size: 0.9rem;
      }

      .mypathsName {
        width: 25%;
        display: flex;
        padding-left: 35px;
        font-weight: 500;
        font-size: 0.8rem;
      }

      .mypathsCountry {
        width: 25%;
        display: flex;
        font-weight: 500;
        font-size: 0.8rem;
        padding-left: 1rem;
      }

      .mypathsMicrosteps {
        width: 25%;
        display: flex;
        font-weight: 500;
        font-size: 0.8rem;
        padding-left: 1rem;
      }
    }

    .mypathsScroll-div {
      width: 100%;
      height: calc(100% - 2.5rem);
      overflow-y: scroll;

      .each-mypaths-data {
        width: 100%;
        border-bottom: 0.5px solid #e5e5e5;
        display: flex;
        font-size: 0.8rem;
        cursor: pointer;
        transition: all ease 0.2s;

        .each-mypaths-name {
          width: 30%;
          padding: 2rem 0 2rem 35px;
          line-height: 1.7;
          font-size: 0.9rem;
        }

        .each-mypaths-desc {
          width: 50%;
          padding: 2rem 0 2rem 0;
          font-weight: 300;
          padding-left: 35px;
          line-height: 1.8;
          // text-transform: none;
          padding-right: 1rem;
        }

        &:hover {
          background: rgba(237, 237, 237, 0.368627451);
        }
      }

      .each-mypaths-data1 {
        width: 100%;
        border-bottom: 0.5px solid #e5e5e5;
        display: flex;
        flex-direction: column;
        font-size: 0.8rem;
        padding: 2rem 0 8rem 0;
        cursor: pointer;
        transition: all ease 0.2s;

        .each-mypaths-detail {
          width: 100%;
          display: flex;
          align-items: center;

          .each-mypathsName {
            width: 25%;
            display: flex;
            align-items: center;
            padding-left: 35px;
            font-weight: 500;
            font-size: 0.9rem;
            gap: 10px;
          }

          .each-mypathsCountry {
            width: 25%;
            display: flex;
            font-weight: 500;
            font-size: 0.9rem;
            padding-left: 1rem;
          }

          .each-mypathsMicrosteps {
            width: 25%;
            display: flex;
            font-weight: 500;
            font-size: 0.9rem;
            padding-left: 1rem;
          }
        }

        .each-mypaths-desc {
          width: 100%;
          padding: 3rem 35px 0 35px;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .each-mypaths-desc-txt {
            font-size: 0.9rem;
            font-weight: 500;
          }

          .each-mypaths-desc-txt1 {
            line-height: 1.8;
            font-size: 0.8rem;
            font-weight: 300;
            text-transform: none;
          }
        }

        &:hover {
          background: rgba(237, 237, 237, 0.368627451);
        }
      }
    }

    .viewpath-container {
      width: 100%;
      height: 100%;
      padding: 2rem 35px;
      color: #1f304f;
      overflow-y: scroll;

      .viewpath-top-area {
        width: 100%;
        height: 8rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        position: relative;

        .viewpath-bold-text {
          font-size: 1.5rem;
          font-weight: 500;
        }

        .viewpath-des {
          font-size: 0.8rem;
          font-weight: 300;
          line-height: 1.7;
        }

        .viewpath-goBack-div {
          position: absolute;
          top: 0;
          right: 0;
          font-weight: 600;
          text-decoration: underline;
          cursor: pointer;
          transition: all ease 0.2s;

          &:hover {
            transform: scale(1.05);
          }
        }
      }

      .viewpath-steps-area {
        display: flex;
        width: 100%;
        gap: 0 4rem;
        flex-wrap: wrap;

        .viewpath-each-j-step {
          width: calc((100% - 8rem) / 3);
          border-radius: 10px;
          border: 1px solid #e5e5e5;
          display: flex;
          flex-direction: column;
          padding: 2rem 1rem;
          gap: 2rem;
          margin-top: 2rem;
          z-index: 2;
          cursor: pointer;
          transition: all ease 0.2s;

          &:hover {
            border: 1px solid #1f304f;
          }

          .viewpath-each-j-step-text {
            width: 100%;
            font-size: 1.1rem;
            font-weight: 500;
          }

          .viewpath-each-j-step-text1 {
            width: 100%;
            font-size: 0.8rem;
            font-weight: 300;
            line-height: 1.7;
            margin-top: -1rem;
          }

          .viewpath-each-j-amount-div {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            .viewpath-each-j-amount {
              font-size: 1.1rem;
              font-weight: 500;
            }
          }
        }

        .viewpath-relative-div {
          position: relative;

          .viewpath-j-arr-div {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-self: center;
            align-items: center;
            left: calc(100% + 1rem);
            z-index: 1;
            cursor: default;
          }
        }
      }
    }
  }
}

.acc-addpath {
  width: 100%;
  height: calc(100% - 2rem);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-bottom: 2rem;
  background-color: #fff; /* Add white background */
  padding: 2rem; /* Add padding around the form */
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  max-width: 800px; /* Limit the maximum width */
  margin: 0 auto; /* Center the form */
}

.acc-addpath .each-acc-addpath-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.acc-addpath .each-acc-addpath-field .each-acc-addpath-field-name {
  font-weight: 500;
  color: #1f304f;
  width: 100%;
}

.acc-addpath .each-acc-addpath-field .each-acc-addpath-field-input {
  width: 100%;
  border-radius: 15px;
  border: 1px solid #e5e5e5;
  display: flex;
}

.acc-addpath .each-acc-addpath-field .each-acc-addpath-field-input input {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 15px;
  padding: 1.5rem;
  font-size: 1rem;
}

.acc-addpath .each-acc-addpath-field .each-acc-addpath-field-input input::placeholder {
  font-weight: 500;
}

.acc-addpath .each-acc-addpath-field .each-acc-addpath-field-input select {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 15px;
  padding: 1.5rem;
  font-size: 1rem;
  border-radius: 15px;
  /* Add space between options */
  padding-right: 2rem; /* Example: Adjust as needed */
  appearance: none; /* Remove default arrow */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1.5em 1.5em;
}

/* Style for the options in the select dropdown */
.acc-addpath .each-acc-addpath-field .each-acc-addpath-field-input select option {
  padding: 0.75rem; /* Add some padding to each option */
}

/* Style for the selected options */
.acc-addpath .each-acc-addpath-field .each-acc-addpath-field-input select option:checked {
  border-radius: 35px;
  border: 0.5px solid #e7e7e7;
  background: linear-gradient(90deg, #47b4d5 0.02%, #29449d 119.26%);
  color: #fff;
}

/* Button Styles */

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}


.form-button {
  padding: 1rem 2rem;
  border-radius: 30px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  min-width: 120px; /* Minimum width for buttons */
  text-align: center; /* Center the text */
}

.cancel-button {
  // background-color: #f44336;
  background: linear-gradient(90deg, #17234e 0.02%, #17234e 119.26%);
  color: white;
  border: none;
}

.save-button {
  // background-color: #4CAF50;
  background: linear-gradient(90deg, #47b4d5 0.02%, #29449d 119.26%);
  color: white;
  border: none;
}

.cancel-button:hover {
  background-color: #dc281e; /* Darker Red */
}

.save-button:hover {
  background-color: #45a049; /* Darker Green */
}

/* Style for the selected option */
.optionCardFullSelected {
  border-radius: 35px;
  border: 0.5px solid #e7e7e7;
  background: linear-gradient(90deg, #47b4d5 0.02%, #29449d 119.26%);
  width: 100%;
  height: 55.991px;
  display: flex;
  padding-left: 20px;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  color: #fff;
  margin-bottom: 1rem;
  text-transform: capitalize;
}

.message-page {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 70vh; /* Occupy full viewport height */
  font-size: 1.5em; /* Make the message larger */
  font-weight: 500;
  color: rgb(40, 43, 40);
}

