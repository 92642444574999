.createPlanB {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  color: #100F0D;

  & > .closeBtn {
    position: absolute;
    top: 2rem;
    right: 2rem;
    padding: 1.5rem;
    border-radius: 50%;
    background: #44c2f4;
    color: white;
    font-weight: 600;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.5rem;
  }

  & > .title {
    font-size: 1.7rem;
    font-weight: 550;
    // padding-bottom: 2rem;
  }

  & > .contentDiv {
    width: 100%;
    // height: calc(100% - 4rem);
    display: flex;
    flex-direction: column;
    // overflow-y: scroll;
    padding-bottom: 2rem;

    & > div {
      padding-top: 2.8rem;
    }

    & > .planType {
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
      // line-height: 2;
      font-size: 1.3rem;
      font-weight: 550;

      // & > .heading {
      // }

      & > .planTypeSelection {
        color: #7d7d7d40;
        font-weight: 550;
        outline: none;
        border: 0.5px solid #e5e5e5;
        border-radius: 10px;
        padding: 1rem 2rem;
        width: 100%;
        position: relative;
        cursor: pointer;
        transition: all ease 0.4s;

        &:hover {
          background-color: #7d7d7d27;
        }

        & > span {
          display: flex;
          gap: 1rem;
          align-items: center;

          & > img {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
          }
        }

        & > img {
          width: 0.8rem;
          height: 0.8rem;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          right: 1.5rem;
        }
      }
    }

    & > .createPlanBtn {
      background: #18191d;
      border-radius: 25px;
      width: 55%;
      padding: 1rem 2rem;
      color: white;
      font-weight: 550;
      font-size: 1.3rem;
      text-align: center;
      cursor: pointer;
      margin-top: 3.5rem;
      transition: all ease 0.4s;

      &:hover {
        padding: 1rem 3rem;
        width: 65%;
      }
    }
  }

  & > .createPlanBtn {
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #18191d;
    border: 0.5px solid #18191d;
    border-radius: 25px;
    cursor: pointer;
    font-weight: 550;
    color: white;
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
}

.inputSingleDropdown {
  font-size: 1.1rem;
  // font-weight: 550;
  display: flex;
  flex-direction: column;
  grid-gap: 0.7rem;
  gap: 0.7rem;
  position: relative;
  margin-top: 2rem;
  .title {
    font-size: 1.1rem;
    // font-weight: 550;
  }
  .select {
    display: flex;
    justify-content: space-between;
    color: #100F0D;
    font-weight: 550;
    outline: none;
    border: 0.5px solid #e5e5e5;
    border-radius: 10px;
    padding: 1rem 2rem;
    width: 100%;
    transition: all ease 0.4s;
    cursor: pointer;
  }

  .selectedDropdown {
    position: absolute;
    left: 0;
    right: 0;
    top: 4.8rem;
    height: max-content;
    background: white;
    display: flex;
    flex-direction: column;
    z-index: 2;

    & > div {
      color: #100F0D;
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;
    }

    & > .customDropdownValue {
      background: #ffffff;
      border: 0.5px solid #e5e5e5;
      border-radius: 10px;
      padding: 0;
      position: relative;
      color: #100F0D;

      & > input {
        padding: 0.75rem;
        border: none;
        outline: none;
        border-radius: 10px;
        font-weight: 600;
        color: #100F0D;

        // width: -webkit-fill-available;
      }
      & > div {
        width: 1.75rem;
        height: 1.75rem;
        color: white;
        font-size: 1rem;
        border-radius: 10px;
        background: #18191d;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }
  }
}

.inputDivs {
  // line-height: 2;
  font-size: 1rem;
  // font-weight: 550;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  position: relative;
  margin-top: 2rem;

  // & > .heading {
  // }

  & > .inputHolder {
    position: relative;

    & > .editIconDiv {
      border: 0.5px solid #e7e7e7;
      position: absolute;
      right: -10px;
      top: -12px;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      border-radius: 50%;
      cursor: pointer;
    }

    & > .inputFields {
      color: #100F0D;
      font-weight: 550;
      outline: none;
      border: 0.5px solid #2c7cb2;
      border-radius: 10px;
      padding: 1rem 2rem;
      width: 100%;
      transition: all ease 0.4s;

      &::placeholder {
        color: rgb(52, 60, 91, 0.75);
      }

      &:hover {
        background-color: #7d7d7d27;
      }
    }

    & > .currencyDiv {
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translate(-50%, -50%);
    }

    & > .currencyDiv1 {
      position: absolute;
      top: 50%;
      right: -1rem;
      transform: translate(-50%, -50%);
      border: 0.5px solid #e5e5e5;
      width: 12%;
      height: 70%;
      border-radius: 5px;
    }

    & > .currencyDiv2 {
      position: absolute;
      top: 50%;
      right: -2rem;
      transform: translate(-50%, -50%);
      height: 70%;
      border-radius: 35px;
      background: #1f304f;
      color: #ffffff;
      display: flex;
      align-items: center;
      padding: 0 1.5rem;
      cursor: pointer;
      font-size: 0.9rem;
    }
  }

  & > .contentBox {
    display: flex;
    // justify-content: space-between;
    gap: 1rem;

    & > .valueBox {
      font-size: 1.3rem;
      border: 0.5px solid #e5e5e5;
      border-radius: 10px;
      padding: 1rem 2rem;
      width: 90%;
      font-weight: 600;
      display: flex;
      gap: 1rem;
      transition: all ease 0.4s;

      // &:hover {
      //   background-color: #7d7d7d27;
      // }

      & > img {
        height: 2rem;
        border-radius: 50%;
      }

      // & > div {
      //   width: inherit;
      // }
    }

    & > .dateBox {
      width: 100%;
      display: flex;
      justify-content: space-between;

      & > div {
        width: 31%;
        border: 0.5px solid #e5e5e5;
        border-radius: 10px;
        padding: 1rem 2rem;
        transition: all ease 0.4s;

        // &:hover {
        //   background-color: #7d7d7d27;
        // }
      }
    }

    & > .imageBox {
      width: 7rem;
      height: 7rem;

      & > img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: contain;
        transition: all ease-in 0.4s;

        // &:hover {
        //   scale: 1.2;
        // }
      }
    }

    & > :nth-child(2) {
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;

      & > img {
        height: 40%;
        max-height: 1.7rem;
        object-fit: contain;
        cursor: pointer;
        transition: all ease-in 0.4s;

        &:hover {
          scale: 1.2;
        }
      }
    }

    & > .newDataAdd {
      display: block;
      border: 0.5px solid #e5e5e5;
      border-radius: 25px;
      padding: 1rem 3rem;
      // min-width: max-content;
      width: max-content;
      text-align: center;
      cursor: pointer;
      transition: all ease-in 0.4s;
      // font-weight: 800;

      &:hover {
        background: #7d7d7d27;
      }
    }
  }

  & > .newDataAdd {
    display: block;
    border: 0.5px solid #e5e5e5;
    border-radius: 25px;
    padding: 1rem 3rem;
    width: max-content;
    // width: 50%;
    text-align: center;
    cursor: pointer;
    transition: all ease-in 0.4s;
    // font-weight: 800;

    &:hover {
      background: #7d7d7d27;
    }
  }
}

.dateSelectionDiv {
  // line-height: 2;
  font-size: 1.3rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;

  & > .dateSelectorDiv {
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;
      gap: 0.2rem;
      justify-content: space-between;
      height: 100%;
      color: #7d7d7d40;
      font-weight: 550;
      outline: none;
      width: 30%;
      // padding: 1rem 2rem;
      // border: 0.5px solid #e5e5e5;
      // border-radius: 10px;
      position: relative;
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        border-radius: 10px;
        background-color: #7d7d7d27;
      }

      & > :first-child {
        border: 0.5px solid #e5e5e5;
        border-radius: 10px;
      }

      & > div {
        width: 100%;
        display: flex;
        // flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 0.2rem;

        & > span {
          width: inherit;
          padding: 1rem 2rem;
          overflow-x: hidden;
          // border: 1px solid red;
          & > input {
            width: 100%;
            height: 100%;
            outline: none;
            border: 0;
          }
        }
        & > img {
          width: 1rem;
          height: 1rem;
          margin-right: 1rem;
        }
      }

      & > .dropDownDiv {
        display: flex;
        flex-direction: column;
        border: 0.5px solid #e5e5e5;
        border-top: 0px;
        height: 500px;
        position: absolute;
        top: 3.5rem;
        left: 0;
        right: 0;
        background-color: white;
        z-index: 1;
        justify-content: flex-start;

        & > div {
          padding: 1rem 2rem;
          width: 100%;
          transition: all ease 0.4s;

          &:hover {
            background-color: #7d7d7d27;
          }
        }
      }
    }
  }
}

.selectOneDiv {
  // line-height: 2;
  font-size: 1.3rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;

  & > .selectoptionsDiv {
    display: flex;
    gap: 1rem;
    justify-content: space-between;

    & > div {
      width: 31%;
      height: 100%;
      // color: #5f6163;
      border-radius: 10px;
      border: 0.5px solid #e5e5e5;
      color: #7d7d7d40;
      font-weight: 550;
      padding: 1rem 2rem;
      text-align: center;
      cursor: pointer;
      transition: all ease 0.4s;

      &:hover {
        background-color: #7d7d7d27;
      }
    }
  }
}

.imageUploadDiv {
  // line-height: 2;
  font-size: 1.1rem;
  // font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  margin-top: 2rem;

  & > .imageDiv {
    // margin-top: 0.5rem;
    min-width: 8vw;
    min-height: 8vw;
    max-width: 8vw;
    max-height: 8vw;
    position: relative;

    & > img {
      min-width: 8vw;
      min-height: 8vw;
      max-width: 8vw;
      max-height: 8vw;
      border-radius: 50%;
      transition: all ease 0.4s;

      // &:hover {
      //   scale: 0.95;
      //   // border: 5px solid #44c2f4;
      // }
    }

    & > .overlayDiv {
      position: absolute;
      width: 20%;
      height: 20%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      & > img {
        animation: heartBeat 0.5s alternate infinite;
      }

      @keyframes heartBeat {
        0% {
          scale: 1;
        }
        100% {
          scale: 1.2;
        }
      }
    }
  }

  & > .uploadFileDiv {
    font-size: 1.1rem;
    transition: all ease 0.4s;
    width: 40%;

    &:hover {
      font-size: 1.3rem;
      font-weight: 550;
    }
    & > .uploadNewPicPlanB {
      display: none;
    }
  }
}

.planTypeCreate {
  width: 100%;
  height: 100%;
  color: #7d7d7d;
  // border: 1px solid red;

  & > .title {
    font-size: 1.7rem;
    font-weight: 550;
    // padding-bottom: 2rem;
  }

  & > .searchDiv {
    display: flex;
    border-radius: 10px;
    border: 0.5px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all ease-in 0.4s;
    position: relative;

    &:hover > input {
      background-color: #7d7d7d27;
    }

    // &:hover > div > div {
    //   background: #44c2f4;
    //   color: white;
    // }

    & > * {
      color: #7d7d7d40;
      font-weight: 600;
      padding: 1rem 2rem;
      // text-align: center;
      height: 100%;
    }

    & > input {
      width: 100%;
      height: 100%;
      outline: none;
      border: 0;
      border-radius: 10px;
      font-size: 1.4rem;
      transition: all ease-in 0.4s;
    }

    & > div {
      padding: 0.7rem 2rem;
      // border: 1px solid green;
      font-weight: 600;
      font-size: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 1rem;

      & > div {
        padding: 0.5rem;
        border-radius: 50%;
        background: #e5e5e5;
        color: white;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all ease-in 0.2s;

        &:hover {
          background: #44c2f4;
          color: white;
        }
      }
    }
  }

  & > .allPlans {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    margin-top: 2.5rem;
    height: calc(100% - 13.5rem);
    border: 0.5px solid #e5e5e5;
    border-radius: 10px;
    padding: 0 2rem;
  }
}

.singlePlan {
  display: flex;
  gap: 1.5rem;
  padding: 2rem 0;
  border-bottom: 0.5px solid #e5e5e5;
  width: 100%;
  // height: 100%;
  color: #7d7d7d;
  cursor: pointer;
  transition: all ease-in 0.4s;

  &:hover {
    // background-color: #7d7d7d27;
    transform: translateX(6%);
  }

  & > img {
    min-width: 3rem;
    min-height: 3rem;
    max-width: 3rem;
    max-height: 3rem;
    border-radius: 50%;
  }

  & > div {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 550;
    // padding-bottom: 2rem;
  }
}

.createPlanBTypeContainer {
  width: 100%;
  height: 100%;
  color: #7d7d7d;

  & > .title {
    font-size: 1.7rem;
    font-weight: 550;
    // padding-bottom: 2rem;
  }

  & > .contentDiv {
    width: 100%;
    // height: calc(100% - 4rem);
    display: flex;
    flex-direction: column;
    // overflow-y: scroll;
    padding-bottom: 2rem;

    & > div {
      padding-top: 2.8rem;
    }

    & > .planCreationBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.2rem 2rem;
      width: 55%;
      background: #44c2f4;
      border-radius: 15px;
      color: white;
      font-weight: 550;
      cursor: pointer;
      margin-top: 2.5rem;
      transition: all ease-in 0.4s;

      &:hover {
        width: 65%;
      }
    }
  }
}

::placeholder {
  color: #7d7d7d40;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #7d7d7d40;
}

::-ms-input-placeholder {
  color: #7d7d7d40;
}

.labelClass{
  font-size: 16px;
  font-weight: 400;
  color: #1F304F;
  padding-bottom: 20px;
}
.inputClass{
  border: 1px solid #2c7cb2;
  border-radius: 10px;
  height: 54px;
  padding: 0px 15px;
  margin-bottom: 10px;
  width: 100%;
}
.textareaClass{
  border: 1px solid #2c7cb2;
  border-radius: 10px;
  height: 171px !important;
  padding: 15px 15px;
  margin-bottom: 10px;
}
.imgContainer{
  display: flex;
  align-items: center;
  .logoText{
    padding-left: 20px;
  }
}