@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.dashboard-body {
  height: calc(100vh);
  width: 100vw;
  display: flex;
  text-transform: capitalize !important;
  overflow: hidden;
  position: relative;
}

.dashboard-screens {
  width: 80vw;

  .row-1 {
    display: flex;
    height: calc(55% - 0.5px);
    border-bottom: 0.5px solid #e5e5e5;
  }

  .box-1 {
    width: 37vw;
    height: 100%;
  }

  .box-2 {
    border-left: 0.5px solid #e5e5e5;
    width: calc(43vw - 0.5px);
    height: 100%;
  }

  .row-2 {
    display: flex;
    height: calc(45% - 0.5px);
    border-bottom: 0.5px solid #e5e5e5;
  }

  .box-3 {
    width: 25vw;
    height: 100%;
  }

  .box-4 {
    border-left: 0.5px solid #e5e5e5;
    width: calc(55vw - 0.5px);
    height: 100%;
  }
}

.dash-nav {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-bottom: 0.5px solid #e5e5e5;
  width: 80vw;
  height: 70px;
}

.search-input-box {
  width: calc(80vw - 171px);
  height: 70px;
  padding: 5px 35px;
  border-right: 0.5px solid #e5e5e5;
}

.search-input {
  outline: none;
  border: none;
  height: 60px;
  width: calc(72vw - 171px);
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1f304f;
}

.search-box {
  border-right: 0.5px solid #e5e5e5;
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-icon {
  height: 25px;
  width: 25px;
}

.full-user {
  width: 110px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 0.2s;
  cursor: pointer;
  &:hover {
    transform: scale(1.2);
  }
}

.arrow-box {
  margin-left: 14px;
}

.user-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.account-container {
  display: flex;
  width: 80vw;
  height: calc(100vh - 70px);
  background-color: #ffffff;
  overflow: hidden;
}

.account-left {
  width: calc(60vw);
  height: calc(100vh - 70px);
  background-color: #ffffff;
  padding: 0px 40px 40px 30px;
}

.account-right {
  border-left: 0.5px solid #e5e5e5;
  width: 20vw;
  height: calc(100vh - 70px);
  background-color: #ffffff;
}

.filter-actions-box {
  display: flex;
  margin: 25px 0px;
}

.filter-action1,
.filter-action2 {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin-right: 25px;
  padding: 16px 45px;
  font-weight: 500;
  font-size: 15px;
  color: #1f304f;
  cursor: pointer;
  @include btn-animate(#1f304f);
}

.all-account {
  height: 87%;
  overflow-y: scroll;
  width: calc(60vw - 70px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 40px;
}

.each-account {
  width: 48%;
  padding: 35px;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  margin-bottom: 30px;
}

.account-img-box {
  display: flex;
  justify-content: center;
  height: 108px;
}

.account-img {
  height: 108px;
  width: 108px;
  border-radius: 50%;
}

.account-name {
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  margin-top: 20px;
  color: #1f304f;
  display: flex;
  justify-content: center;
}

.account-work {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  padding-top: 7px;
  color: #1f304f;
  display: flex;
  justify-content: center;
  text-align: center;
  min-height: 4rem;
}

.account-work-title {
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
  color: #1f304f;
  transition: all ease 0.2s;

  &:hover {
    transform: scale(1.1);
  }
}

.account-country {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #1f304f;
  margin-top: 35px;
  margin-bottom: 15px;
}

.account-countries-all {
  width: 90%;
  display: flex;
  margin-bottom: 25px;
}

.account-countries-each {
  width: 32%;
  padding: 15px 0px;
  background: rgba(255, 255, 255, 0.25);
  border: 0.5px solid #e5e5e5;
  border-radius: 35px;
  cursor: pointer;
  @include btn-animate(#1f304f);
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  color: #1f304f;
}

.account-countries-each1 {
  width: 32%;
  margin-left: 20px;
  padding: 15px 0px;
  background: rgba(255, 255, 255, 0.25);
  border: 0.5px solid #e5e5e5;
  border-radius: 10px;
  cursor: pointer;
  @include btn-animate(#1f304f);
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  color: #1f304f;
}

.account-countries-more {
  width: 32%;
  margin-left: 20px;
  padding: 15px 0px;
  background: rgba(31, 48, 79, 0.1);
  border-radius: 10px;
  cursor: pointer;
  @include btn-animate(#1f304f);
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  color: #1f304f;
}

.account-speaclities {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #1f304f;
  margin-top: 35px;
  margin-bottom: 15px;
}

.account-speaclities-all {
  width: 100%;
  display: flex;
  margin-bottom: 25px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.account-speaclities-each {
  margin-bottom: 20px;
  width: 60%;
  padding: 15px 0px;
  background: rgba(255, 255, 255, 0.25);
  border: 0.5px solid #e5e5e5;
  border-radius: 35px;
  cursor: pointer;
  @include btn-animate(#1f304f);
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  color: #1f304f;
}

.account-speaclities-each1 {
  margin-bottom: 20px;
  width: 35%;
  padding: 15px 0px;
  background: rgba(255, 255, 255, 0.25);
  border: 0.5px solid #e5e5e5;
  border-radius: 10px;
  cursor: pointer;
  @include btn-animate(#1f304f);
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  color: #1f304f;
}

.account-speaclities-each2 {
  margin-bottom: 20px;
  width: 35%;
  padding: 15px 0px;
  background: rgba(255, 255, 255, 0.25);
  border: 0.5px solid #e5e5e5;
  border-radius: 10px;
  cursor: pointer;
  @include btn-animate(#1f304f);
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  color: #1f304f;
}

.account-speaclities-each3 {
  margin-bottom: 20px;
  width: 60%;
  padding: 15px 0px;
  background: rgba(255, 255, 255, 0.25);
  border: 0.5px solid #e5e5e5;
  border-radius: 10px;
  cursor: pointer;
  @include btn-animate(#1f304f);
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  color: #1f304f;
}

.account-see-more {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px;
  border-radius: 35px;
  background: #59a2dd;
  border: 0.5px solid #59a2dd;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  cursor: pointer;
  @include btn-animate(#ffffff);
}

.service-right-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1f304f;
  margin-bottom: 20px;
}

.account-right-box1 {
  height: 50%;
  padding: 20px 25px;
  border-bottom: 0.5px solid #e5e5e5;
}

.account-right-box2 {
  height: 50%;
  padding: 20px 25px;
}

.check-div {
  margin: 10px 0px;
  display: flex;
  align-items: center;
}

.check-input {
  height: 25px;
  width: 25px;
  accent-color: #1f304f;
}

.check-label {
  margin-left: 18px;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  color: #1f304f;
}

.service-right-btn {
  margin-top: 20vh;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px;
  border-radius: 35px;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #1f304f;
  cursor: pointer;
  @include btn-animate(#1f304f);
}

.service-container {
  // width: 80vw;
  // height: calc(100vh - 70px);
  // background-color: #FFFFFF;
  // padding: 20px 0px 0px 20px;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-evenly;
  display: flex;
  overflow-x: hidden;
}

.service-main {
  width: 80vw;
  overflow-x: scroll;
  height: calc(100vh - 70px);
  background-color: #ffffff;
  padding: 40px 0px 20px 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.serviceby-allbox {
  display: -webkit-inline-box;
  overflow-x: scroll;
  height: 31vh;
  // width: 100%;
}

.check {
  border: 0.5px solid #e5e5e5;
  border-radius: 5px;
  padding: 6px 6px 2px 6px;
}

.pagemask {
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden !important;
}

.endbox {
  background-color: #ffffff;
  border-radius: 25px;
  padding: 80px;
  width: fit-content;
  text-align: center;
}

.full-box {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.follow-text {
  font-weight: 600;
  font-size: 23px;
  line-height: 45px;
  margin-top: 30px;
  color: #1f304f;
}

///click arrow

.zoom1 {
  background: #f3f4f5;
  border: 0;
  border-radius: 30px;
  box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
  cursor: pointer;
  display: inline-block;
  height: 40px;
  overflow: hidden;
  transition: all 0.3s ease-out;
  width: 40px;
}

.check1-text {
  display: none;
}

.clickarrow-img {
  height: 20px;
  width: 20px;
}

.each-service {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 20px;
  margin-right: 35px;
  width: 400px;
  height: 31vh;

  &:hover {
    background-color: #f5f5f5;

    .check1-text {
      color: #182542;
      display: inline-flex;
      margin-left: 9px;
      margin-top: 5px;
      font-size: 15px;
      font-weight: 700;
    }

    .clickarrow-img {
      margin-left: 0.2rem;
      margin-top: 0.3rem;
      width: 20px;
    }

    .zoom1 {
      background: #fffbf0;
      height: 40px;
      text-align: center;
      width: 120px;
    }
  }
}

.serviceby-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: fit-content;
}

.serviceby-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #1f304f;
  margin-right: 30px;
}

.serciceby-option-box {
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 35px;
  padding: 10px 22px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}

.serviceby-img {
  height: 27px;
  width: 27px;
  margin-right: 8px;
}

.serviceby-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #1f304f;
  margin-right: 20px;
}

.each-service-title {
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #1f304f;
  margin-top: 14px;
}

.each-service-desc {
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  /* or 167% */

  display: flex;
  align-items: center;

  color: #1f304f;
  margin: 14px 0px;
}

.each-service-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.each-service-price {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;

  color: #1f304f;
  margin-right: 25px;
}

.serviceby-title1 {
  margin: 14px 0px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #1f304f;
}

.each-service-img {
  height: 35px;
  width: 35px;
}

.crm-all-menu,
.services-all-menu {
  display: flex;
  align-items: center;
  padding: 12px 0px;
}

.crm-each-menu,
.services-each-menu {
  padding: 10px 30px;
  border-radius: 35px;
  font-weight: 400;
  font-size: 15px;
  color: #1f304f;
  margin-left: 2.5vw;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    transition: all ease 0.2s;
  }
}

.crm-tab {
  background: linear-gradient(90deg,#29449D 4.23%, #47B4D5 100%);
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: 10px;
}

.crm-purchase-tab {
  background: linear-gradient(90deg,#29449D 4.23%, #47B4D5 100%);
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: 10px 4.5vw;
}

.each-purchase {
  display: flex;
  align-items: center;
  padding: 15px 4.5vw;
  transition: all ease 0.3s;
  cursor: pointer;
  border-bottom: 0.5px solid #e7e7e7;
  &:hover {
    transform: scale(1.02);
    background-color: rgba(237, 237, 237, 0.368627451);
  }
}

.each-purchase-services {
  display: flex;
  align-items: center;
}

.each-purchase-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.each-product-iconbox {
  border: 0.5px solid #ededed;
  border-radius: 5px;
  margin-right: 10px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.each-product-icon {
  height: 45px;
  width: 45px;
}

.crm-purchase-col1,
.each-purchase-clients {
  width: 16vw;
}

.crm-purchase-col2,
.each-purchase-services {
  width: 28vw;
}
.crm-purchase-col7{
  width: 18vw;
}
.crm-purchase-col3,
.each-purchase-receipt {
  width: 22vw;
}
.crm-purchase-col4,
.each-purchase-status {
  width: 4vw;
}

.purchase-alldata {
  overflow-y: scroll;
  height: calc(100vh - 189px);
}

.each-purchase-head {
  font-weight: 600;
  font-size: 15px;
  color: #1f304f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 7px;
}

.each-purchase-text {
  font-weight: 400;
  font-size: 12px;
  color: #1f304f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.each-purchase-statustext {
  font-weight: 600;
  font-size: 20px;
  color: #1f304f;
}

.users-alldata {
  width: 100%;
  overflow-y: scroll;
  height: calc(100vh - 189px);

  .each-userData {
    width: 100%;
    display: flex;
    padding: 15px 35px;
    cursor: pointer;
    transition: all ease 0.2s;
    border-bottom: 0.5px solid #e7e7e7;
    &:hover {
      transform: scale(1.02);
      background-color: rgba(237, 237, 237, 0.368627451);
    }

    .each-user-email {
      width: 20%;
      text-align: left;
      font-weight: 500;
      font-size: 1rem;
      display: block;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.clients-alldata {
  width: 100%;
  overflow-y: scroll;
  height: calc(100vh - 189px);

  .each-clientData {
    width: 100%;
    display: flex;
    padding: 15px 35px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all ease 0.2s;
    border-bottom: 0.5px solid #e7e7e7;
    &:hover {
      transform: scale(1.02);
      background-color: rgba(237, 237, 237, 0.368627451);
    }

    .each-client-name {
      width: 25%;
      display: flex;
      text-align: left;
      font-weight: 500;
      font-size: 1rem;
    }

    .each-client-email {
      width: 25%;
      display: flex;
      text-align: left;
      font-weight: 500;
      text-transform: none;
      font-size: 1rem;
      padding-left: 1rem;
    }
    .each-client-new {
      width: 30%;
      display: flex;
      text-align: left;
      font-weight: 500;
      text-transform: none;
      font-size: 1rem;
      padding-left: 1rem;
    }
  }
}

.crm-each-col {
  margin: 0px 6vw;
}

.crm-follow-tab {
  background: linear-gradient(90deg,#29449D 4.23%, #47B4D5 100%);
  // background: #1f304f;
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: 10px 4.5vw;
  margin-bottom: 20px;
}

.crm-follow-col1,
.follower-details {
  width: 70%;
  display: flex;
  align-items: center;
}

.crm-follow-col2,
.follow-time {
  width: 30%;
}

.follower-box {
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: 22px 2.5vw;
  border-radius: 10px;
  width: 75vw;
  margin: auto;
  transition: all ease 0.3s;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
}

.follower-mail {
  margin-left: 10px;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #1f304f;
}

.follower-name {
  margin-top: 5px;
  margin-left: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #1f304f;
}

.follow-time {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #1f304f;
}

.follow-data-main {
  height: calc(100vh - 189px);
  overflow-y: scroll;
}

.acc-popular {
  padding: 20px 35px;
  background: #ffffff;
  border: 0.5px solid #2c7cb2 !important;
  position: absolute;
  border-radius: 5px !important;
  top: 25% !important;
  left: 40%;
  transform: translate(-50%, -50%);
  z-index: 22;
  width: 550px !important;
  height: auto; /* Adjust height based on content */
  max-height: calc(100% - 200px) !important; /* Ensures it doesn't exceed screen height */
  overflow-y: auto; /* Enables scrolling if content overflows */
  -webkit-animation: righttoleft 0.15s ease-in forwards;
  animation: righttoleft 0.15s ease-in forwards;

  .acc-addpath {
    width: 100%;
    height: calc(100% - 2rem);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-bottom: 2rem;

    .each-acc-addpath-field {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .each-acc-addpath-field-name {
        font-weight: 500;
        color: #1f304f;
        width: 100%;
      }

      .each-acc-addpath-field-input {
        width: 100%;
        border-radius: 15px;
        border: 1px solid #e5e5e5;
        display: flex;

        input {
          width: 100%;
          border: none;
          outline: none;
          border-radius: 15px;
          padding: 1.5rem;
          font-size: 1rem;

          &::placeholder {
            font-weight: 500;
          }
        }

        .years-div {
          width: 30%;
          height: 100%;
          border-left: 1px solid #e5e5e5;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
        }

        textarea {
          width: 100%;
          min-height: 10rem;
          border-radius: 15px;
          border: 1px solid #e5e5e5;
          padding: 1.5rem;
          font-size: 1rem;
        }

        .arrow-box {
          width: 15%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all ease 0.2s;

          &:hover {
            transform: scale(1.5);
          }

          img {
            display: flex;
          }
        }

        .hidden-steps {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          width: 100%;
          padding: 2rem 1rem 1rem;
          border-radius: 15px;
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          border-top: none;

          .each-hidden-step {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            cursor: pointer;

            .stepp-textt {
              font-weight: 600;
              text-transform: capitalize;
            }

            .stepp-textt1 {
              font-size: 0.8rem;
              font-weight: 300;
              line-height: 1.6;
            }
          }
        }
      }

      .each-acc-addpath-field-flex {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem 0.5rem;

        div {
          height: 3rem;
          border-radius: 35px;
          border: 1px solid #e5e5e5;
          padding: 0 3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          cursor: pointer;
          transition: all ease 0.2s;

          &:hover {
            font-weight: 700;
          }
        }
      }

      .submit-path-btn {
        width: 100%;
        color: white;
        font-weight: 600;
        background: linear-gradient(90deg, #2c7cb2 0.02%, #2c7cb2 119.26%) !important;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.5rem;
        border-radius: 35px;
        cursor: pointer;
        transition: all ease 0.3s;

        &:hover {
          color: #1f304f;
          background: white;
          border: 1px solid #e5e5e5;
        }
      }

      .go-back-btn {
        margin-top: -0.5rem;
        width: 100%;
        color: #1f304f;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.5rem;
        border: 1px solid #e5e5e5;
        border-radius: 35px;
        cursor: pointer;
        transition: all ease 0.3s;

        &:hover {
          background: #1f304f;
          color: white;
          border: none;
        }
      }
    }

    .selected-steps {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .each-selected-step {
        display: flex;
        width: 95%;
        flex-direction: column;
        gap: 1rem;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        padding: 1.5rem;
        position: relative;

        .stepp-textt {
          font-weight: 600;
          text-transform: capitalize;
        }

        .stepp-textt1 {
          font-size: 0.8rem;
          font-weight: 300;
          line-height: 1.6;
        }

        .trash-icon-div {
          position: absolute;
          right: -18.5px;
          top: -18.5px;
          cursor: pointer;
          transition: all ease 0.2s;

          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}

.acc-popular1 {
  padding: 20px 35px;
  background: #ffffff;
  border: 0.5px solid #ededed;
  position: absolute;
  top: 70px;
  right: 0%;
  z-index: 22;
  width: 460px;
  height: calc(100vh - 200px);
  -webkit-animation: righttoleft 0.15s ease-in forwards;
  animation: righttoleft 0.15s ease-in forwards;

  .acc-popular-top1 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 3rem;

    .acc-popular-head1 {
      font-weight: 600;
      font-size: 22px;
      display: flex;
      align-items: center;
      color: #100f0d;
    }
  }

  .acc-mt-div {
    width: 100%;
    height: calc(100% - 3rem);
    margin-top: 3rem;

    .acc-sub-text {
      width: 100%;
      height: 4rem;
      text-transform: none;
    }

    .acc-scroll-div {
      width: 100%;
      height: calc(100% - 7rem - 70px);
      // border: 1px solid red;
      overflow-y: scroll;

      .acc-step-box4 {
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        height: 84px;
        display: flex;
        align-items: center;
        padding-left: 25px;
        margin-bottom: 15px;
        font-weight: 500;
        font-size: 16px;
        color: #1f304f;
        cursor: pointer;
        &:hover {
          border-color: #182542;
        }
      }
      .acc-step-box4-selected {
        background: #ffffff;
        border: 1px solid #182542;
        border-radius: 15px;
        height: 84px;
        display: flex;
        align-items: center;
        padding-left: 25px;
        margin-bottom: 15px;
        font-weight: 500;
        font-size: 16px;
        color: #1f304f;
        cursor: pointer;
        &:hover {
          border-color: #182542;
        }
      }

      .acc-sub-textt {
        width: 100%;
        text-transform: none;
        margin-bottom: 1rem;
      }

      .acc-step-box5 {
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        min-height: 84px;
        display: flex;
        align-items: center;
        padding-left: 25px;
        margin-bottom: 3rem;
        font-weight: 500;
        font-size: 16px;
        color: #100f0d;
      }

      .acc-step-box6 {
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        height: 84px;
        display: flex;
        padding: 0 25px;

        input {
          width: 100%;
          height: 100%;
          border: none;
          font-size: 1rem;
          font-weight: 500;
          color: #100f0d;
        }
      }
    }

    .save-Btn {
      background: linear-gradient(90deg, #47B4D5 0.02%, #29449D 119.26%);
      color: white;
      font-weight: 500;
      height: 61px;
      display: flex;
      width: 388.4px;
      justify-content: center;
      align-items: center;
      border-radius: 35px;
      position: fixed;
      bottom: 6rem;
      transition: all ease 0.2s;
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
      }
    }

    .goBack3 {
      font-weight: 500;
      height: 61px;
      margin-top: 15px;
      display: flex;
      width: 388.4px;
      justify-content: center;
      align-items: center;
      background: #f1f1f1;
      border-radius: 35px;
      color: #100f0d;
      position: fixed;
      bottom: 20px;
      transition: all ease 0.2s;
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
      }
    }
    .goBack5 {
      font-weight: 500;
      height: 61px;
      margin-top: 15px;
      display: flex;
      width: 388.4px;
      justify-content: center;
      align-items: center;
      background: #f1f1f1;
      border-radius: 35px;
      color: #100f0d;
      position: fixed;
      bottom: 90px;
      transition: all ease 0.2s;
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
      }
    }

    .popularlogo1 {
      background-color: transparent;
      bottom: 0;
      height: 100%;
      width: calc(100%);
      align-items: center;
      overflow: hidden !important;
      position: fixed !important;
      right: 0;
      top: 0;
      z-index: 11;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .popularlogoimg1 {
        width: 250px;
        height: 220px;
        animation: heartbeat 1.3s infinite;
      }
    }
  }

  .success-box2 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 400;
    text-transform: none;
    font-size: 1.1rem;
    line-height: 1.5;
  }
}

@keyframes righttoleft {
  0% {
    transform: translateX(100px);
  }
  25% {
    transform: translateX(75px);
  }
  50% {
    transform: translateX(50px);
  }
  75% {
    transform: translateX(25px);
  }
  100% {
    transform: translateX(0px);
  }
}

.acc-popular-top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.acc-popular-head {
  font-weight: 600;
  font-size: 22px;
  display: flex;
  align-items: center;
  color: #1f304f;
}

.acc-step-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1f304f;
  margin-bottom: 15px;
}

.acc-step-text1 {
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1f304f;
  margin-bottom: 1.5rem;
  margin-top: 3rem;
}

.acc-step-box {
  background: #ffffff;
  border: 1px solid #2c7cb2 !important;
  border-radius: 15px;
  height: 70px !important;
  display: flex;
  align-items: center;
  padding-left: 25px;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
  color: #1f304f;
  cursor: pointer;
  &:hover {
    border-color: #182542;
  }
}

.acc-step-box1 {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  height: 296px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
  color: #1f304f;
  cursor: pointer;
  &:hover {
    border-color: #182542;
  }
}

.scroll-box {
  width: 100%;
  height: calc(100% - 9.25rem);
  overflow-y: scroll;
}

.acc-step-box2 {
  background: #ffffff;
  border-radius: 35px;
  border: 0.5px solid #e7e7e7;
  height: 4rem;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  margin-bottom: 15px;
  font-size: 16px;
  color: #1f304f;
  gap: 5px;
  cursor: pointer;
  &:hover {
    border-color: #182542;
  }

  img {
    width: 100%;
  }
}

.acc-step-box3 {
  background: #ffffff;
  border-radius: 35px;
  border: 0.5px solid #e7e7e7;
  height: 4rem;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  margin-bottom: 15px;
  font-size: 16px;
  color: #1f304f;
  padding-right: 2rem;

  .coin-details-div {
    width: 50%;
    height: 100%;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 5px;

    img {
      width: 100%;
    }
  }

  .amount-details-div {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 600;

    input {
      width: 100%;
      height: 100%;
      border: none;
      text-align: end;
      font-weight: 600;
      font-size: 16px;
      color: #1f304f;
    }
  }
}

.back-next-btns {
  width: 100%;
  height: 3.25rem;
  display: flex;
  justify-content: space-between;

  .back-Btn {
    width: 49%;
    background: #1f304f;
    color: white;
    font-weight: 500;
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all ease 0.3s;

    &:hover {
      transform: scale(1.05);
    }
  }

  .next-Btn {
    width: 49%;
    background: #ff5e5e;
    color: white;
    font-weight: 500;
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all ease 0.3s;

    &:hover {
      transform: scale(1.05);
    }
  }
}

.acc-step-allbox {
  overflow-y: scroll;
  height: calc(51vh) !important;
}

.acc-step-allbox1 {
  overflow-y: scroll;
  height: calc(75vh);
}

.goBack {
  font-weight: 700;
  height: 41px !important;
  margin-top: 5px !important;
  display: flex;
  width: 388.4px;
  justify-content: center;
  align-items: center;
  background: #1f304f;
  border-radius: 10px;
  color: #ffffff;
  @include btn-animate(#fff);
  // position: fixed;
  bottom: 20px;
  right:70px;
}

.goBack2 {
  font-weight: 500;
  height: 61px;
  margin-top: 15px;
  display: flex;
  width: 388.4px;
  justify-content: center;
  align-items: center;
  background: #f1f1f1;
  border-radius: 35px;
  color: #100f0d;
  position: fixed;
  bottom: 20px;
  transition: all ease 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
}

.goNext {
  font-weight: 700;
  height: 61px;
  margin-top: 15px;
  display: flex;
  width: 388.4px;
  justify-content: center;
  align-items: center;
  background: #59a2dd;
  border-radius: 10px;
  color: #ffffff;
  @include btn-animate(#fff);
  left:45px;
}

.goBack1 {
  font-weight: 700;
  height: 61px;
  margin: 15px 0px;
  display: flex;
  width: 388.4px;
  justify-content: center;
  align-items: center;
  background: #1f304f;
  border-radius: 10px;
  color: #ffffff;
  @include btn-animate(#fff);
  left:45px;
}

.acc-upload {
  margin-bottom: 20px;
}

.acc-step-input {
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 19px;
  color: #1f304f;
  width: 333px;
  height: 100%;
}

.acc-step-feildHead {
  border-left: 1px solid #e5e5e5;
  border-radius: 0px 15px 15px 0px;
  font-size: 19px;
  display: flex;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #1f304f;
  width: 108px;
  height: 100%;
}

.acc-step-input2 {
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 19px;
  color: #1f304f;
  width: 280px;
  height: 100%;
}

.acc-step-input1 {
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 19px;
  color: #1f304f;
  width: 333px;
  height: 80%;
  resize: none;
}

.acc-upload {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.acc-upload-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #1f304f;
}

.acc-upload-imgbox {
  border: 0.5px solid #e7e7e7;
  border-radius: 5px;
  width: 103px;
  height: 103px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.acc-upload-img {
  width: 70px;
  height: 70px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.success-box {
  height: 80%;
  display: flex;
  width: 370px;
  align-items: center;
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 45px;
}

.success-box1 {
  height: 100%;
  display: flex;
  width: 370px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 45px;
}

input[type="file"] {
  height: 100%;
  width: 100%;
  display: none;
}

.popularlogo {
  background-color: transparent;
  bottom: 0;
  height: 100%;
  width: calc(100%);
  align-items: center;
  overflow: hidden !important;
  position: fixed !important;
  right: 0;
  top: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .popularlogoimg {
    width: 250px;
    height: 220px;
    animation: heartbeat 1.3s infinite;
  }
}

.service-body {
  background-color: #fff;
  height: calc(100vh - 133.2px);
  width: 80vw;
  display: flex;
}

.service-body-left {
  background-color: #fff;
  height: calc(100vh - 133.2px);
  // width: calc(60vw - 0.5px);
  width: 80vw;
  overflow-y: scroll;
  padding: 10px 2.5vw 35px 2.5vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0 5%;
}

.each-service-map {
  // width: 47%;
  width: 30%;
}

.service-body-right {
  background-color: #fff;
  border-left: 0.5px solid #e5e5e5;
  height: calc(100vh - 133.2px);
  width: 20vw;
  overflow-y: scroll;
}

.service-box1 {
  padding: 20px 25px;
  height: 35vh;
  border-bottom: 0.5px solid #e5e5e5;
  width: 20vw;
}

.each-service-img {
  height: 40px;
  width: 40px;
}

.each-service-map {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 25px;
  margin-top: 35px;
  min-height: 250px;
  position: relative;
  height: 250px;
}

.dot-box {
  display: flex;
  justify-content: end;
  transition: all ease 0.2s;
  position: absolute;
  right: 20px;

  .dot-icon {
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
    }
  }
}

.serv-title {
  font-weight: 500;
  font-size: 17px;
  color: #1f304f;
  margin: 20px 0px;
}

.serv-subtext {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  color: #1f304f;
  margin-bottom: 20px;
}

.serv-price {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1f304f;
}

.pf-main {
  width: 80vw;
  padding: 30px 40px;
  margin: auto;
  display: flex;
}

.pf-left {
  min-width: 70%;
  /* margin-right: 30px; */
  // padding: 35px;
  height: calc(100vh - 70px);
  overflow-y: scroll;
  overflow-x: hidden;
  background: #ffffff;
  border-right: 0.5px solid #e5e5e5;
}

.create-acc {
  // background: #1f304f;
  border: 1px solid #2c7cb2 !important;
  border-radius: 15px;
  // font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #100f0d;
  padding: 15px !important;
  margin: 15px !important;
  width: 75vw;
  justify-content: space-between;
  cursor: pointer;
  transition: all ease 0.2s;

  &:hover {
    transform: scale(1.03);
  }
}

.pfr-1 {
  padding: 30px;
  background: #ffffff;
  border-bottom: 0.5px solid #ebebeb;
  // border-radius: 15px;
  width: 100%;
  height: calc(100% / 3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pfr-2 {
  // margin-top: 30px;
  padding: 30px;
  background: #ffffff;
  border-bottom: 0.5px solid #ebebeb;
  // border-radius: 15px;
  width: 100%;
  height: calc(100% / 3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pfr-head {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #1f304f;
}

.pfr-desc {
  margin-top: 20px;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  color: #1f304f;
}

.pfr-btn {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px;
  border-radius: 35px;
  background: #59a2dd;
  border: 0.5px solid #59a2dd;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  cursor: not-allowed;
  opacity: 0.25;
  // @include btn-animate(#ffffff);
}

.pfr-btn1 {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px;
  border-radius: 35px;
  border: 0.5px solid #e5e5e5;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  max-height: 3.3rem;
  min-height: 3.3rem;

  div {
    color: #1f304f;
    width: 50%;
    border-radius: 35px;
    display: flex;
    cursor: pointer;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    transition: all ease 0.2s;

    &:hover {
      // transform: scale(1.05);
      font-weight: 600;
    }
  }
}

.pfl-box {
  margin: 30px 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.pfl-box-full {
  margin: 30px 0px;
  width: 100%;
}

.pfl-boxl {
  width: 45%;
}

.pfl-boxr {
  width: 45%;
}

.pfl-box-label {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #1f304f;
  margin-bottom: 10px;
}

.pfl-box-inp {
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 35px;
  padding: 12px 2rem;
  white-space: nowrap;
  // overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 15px;
  color: #1f304f;
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
}

.editIconDiv {
  border: 0.5px solid #e7e7e7;
  position: absolute;
  right: -10px;
  top: -12px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
}

.pfl-box-inp1 {
  width: 100%;
}

.pfl-box-inp-full {
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 35px;
  padding: 12px 2rem;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  align-items: center;
  color: #1f304f;
  position: relative;
}

.popularS {
  position: fixed;
  top: 55% !important;
  left: 50% !important;
  transform: translate(-50%, -50%); /* Ensures perfect centering */
  height: auto; /* Adjusts to content height */
  width: 60% !important; /* Adjust the width as needed */
  max-width: 600px !important; /* Sets a maximum width */
  max-height: 500px !important; /* Limits the maximum height */
  padding: 2rem; /* Adds space inside the form */
  background: white; /* Sets a white background */
  border: 0.5px solid #2c7cb2; /* Light border around the form */
  border-left: 0.5px solid #2c7cb2 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  z-index: 3; /* Ensures it appears above other elements */
  transition: all ease-in 0.4s;

  .head-txt {
    font-size: 1.5rem;
    font-weight: 550;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 6rem;
    color: #1f304f;

    .close-div {
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.07);
      }
    }
  }

  

  .overall-div {
    width: 100%;
    height: calc(100% - 11rem);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    color: #1f304f;

    .line-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;

      .linee {
        width: 42%;
        height: 1px;
        background: #e5e5e5;
      }

      .new-txt {
        font-weight: 600;
        font-size: 1.15rem;
        color: #1f304f;
      }
    }

    .upload {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 2rem 0rem;
      margin-top: 1rem;

      .imgUpload {
        border: 0.5px solid #e7e7e7;
        padding: 1rem;
        border-radius: 5px;
        cursor: pointer;
        transition: all ease 0.2s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .subbTxt {
      font-size: 1.2rem;
      font-weight: 500;
      margin-top: 1rem;
      color: #1f304f;
      margin-bottom: 0.5rem;
    }

    .each-action1 {
      display: flex;
      gap: 10px;
      align-items: center;
      border: 1px solid #e5e5e5;
      border-radius: 15px;
      cursor: pointer;
      padding: 2rem 1.5rem;
      margin-top: 1rem;
      transition: all ease 0.2s;
      color: #1f304f;

      &:hover {
        background: #e7e7e7;
      }

      .partition {
        width: 100%;
        height: 100%;
        display: flex;

        div {
          width: 30%;
          height: 100%;
        }

        input {
          width: 70%;
          height: 100%;
          border: none;
          background: transparent;
          text-align: end;
          color: #1f304f;
          &::placeholder {
            font-weight: 550;
          }
        }
      }

      div {
        font-weight: 500;
        color: #1f304f;
      }

      img {
        width: 25px;
        height: 25px;
      }

      input {
        width: 100%;
        height: 100%;
        border: none;
        background: transparent;
        color: #1f304f;
        &::placeholder {
          font-weight: 550;
        }
      }

      .bgColorDiv {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);
        border: 0.5px solid #e5e5e5;
        width: 12%;
        height: 60%;
        border-radius: 5px;
      }
    }

    .each-action2 {
      display: flex;
      gap: 10px;
      align-items: center;
      border: 1px solid #e5e5e5;
      border-radius: 15px;
      cursor: pointer;
      margin-top: 1rem;
      transition: all ease 0.2s;
      color: #1f304f;

      &:hover {
        background: #e7e7e7;
      }

      input {
        width: 80%;
        height: 100%;
        border: none;
        padding: 2rem 1.5rem;
        background: transparent;
        &::placeholder {
          font-weight: 550;
        }
      }

      .currencyDiv {
        width: 20%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 0.5px solid #e5e5e5;
        font-weight: 700;
        color: #1f304f;
      }
    }

    .successMsg {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 1.5rem;
      text-align: center;
      color: #1f304f;
    }

    .coverPic-container {
      width: 100%;
      height: 19rem;
      position: relative;

      .coverPicDiv {
        width: 100%;
        height: 15rem;
        border-bottom: 0.5px solid #e5e5e5;
        border-top: 0.5px solid #e5e5e5;
        z-index: 1;
      }

      .logoDiv {
        width: 140px;
        height: 140px;
        position: absolute;
        left: 12.5rem;
        top: 11rem;
        z-index: 2;
        background: #ffffff;
        border-radius: 50%;
        border: 1px solid #e5e5e5;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .inputs-container {
      width: 100%;
      padding: 0 3rem;
      margin-top: 3rem;
    }

    .btnss-div {
      width: 100%;
      height: calc(100% - 10rem);
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .eachh-btnn {
        width: 100%;
        border-radius: 35px;
        border: 0.5px solid #e7e7e7;
        padding: 1rem 2rem;
        color: #1f304f;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          font-weight: 600;
        }
      }

      .eachh-btnn-selected {
        width: 100%;
        border-radius: 35px;
        border: 1px solid #1f304f;
        padding: 1rem 2rem;
        color: #1f304f;
        cursor: pointer;
        transition: all ease 0.2s;
        font-weight: 600;
      }
    }

    .leveltwo-steps {
      width: 100%;
      height: calc(100% - 5rem);
      display: flex;
      flex-direction: column;
      gap: 3rem;

      .each-leveltwo-field {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .input-boxx {
          border-radius: 35px;
          border: 0.5px solid #e7e7e7;
          padding: 1rem 2rem;

          input {
            border: none;
            outline: none;
          }
        }

        .input-boxxes {
          display: flex;
          gap: 1rem;
          flex-wrap: wrap;

          div {
            border-radius: 15px;
            border: 0.5px solid #e7e7e7;
            padding: 1rem 2rem;
            cursor: pointer;
            transition: all ease 0.2s;

            &:hover {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .stepBtns {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;

    div {
      background: #18191d;
      width: 100%;
      border-radius: 35px;
      color: #ffffff;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .stepBtnss {
    width: 100%;
    height: 3.5rem;
    display: flex;
    justify-content: space-between;
    padding: 0;

    div {
      width: 48%;
      border-radius: 35px;
      color: #ffffff;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .stepBtns1 {
    width: 100%;
    height: 15rem;
    // display: flex;
    // justify-content: space-between;
    padding: 0.5rem 0;

    div {
      background: #18191d;
      width: 100%;
      border-radius: 35px;
      color: #ffffff;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all ease 0.2s;
      margin-bottom: 1rem;

      &:hover {
        // transform: scale(1.05);
        font-size: 18px;
      }
    }
  }

  .successMsg {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;
    color: #1f304f;
  }
}



// added by chiranjeeb

.popularS1 {
  position: fixed;
  height: calc(100% - 70px);
  top: 70px;
  border-left: 0.5px solid #e5e5e5;
  // border-top: 0.5px solid #e5e5e5;
  width: 80%;
  max-width: 80%;
  background: white;
  z-index: 3;
  padding: 1rem 3rem 20px;
  transition: all ease-in 0.4s;
  right: 0;

  .head-txt {
    font-size: 1.5rem;
    font-weight: 550;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 6rem;
    color: #1f304f;

    .close-div {
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.07);
      }
    }
  }

  .overall-div {
    width: 100%;
    height: calc(100% - 11rem);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    color: #1f304f;

    .line-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;

      .linee {
        width: 42%;
        height: 1px;
        background: #e5e5e5;
      }

      .new-txt {
        font-weight: 600;
        font-size: 1.15rem;
        color: #1f304f;
      }
    }

    .upload {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 2rem 0rem;
      margin-top: 1rem;

      .imgUpload {
        border: 0.5px solid #e7e7e7;
        padding: 1rem;
        border-radius: 5px;
        cursor: pointer;
        transition: all ease 0.2s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .subbTxt {
      font-size: 1.2rem;
      font-weight: 500;
      margin-top: 1rem;
      color: #1f304f;
      margin-bottom: 0.5rem;
    }

    .each-action1 {
      display: flex;
      gap: 10px;
      align-items: center;
      border: 1px solid #e5e5e5;
      border-radius: 15px;
      cursor: pointer;
      padding: 2rem 1.5rem;
      margin-top: 1rem;
      transition: all ease 0.2s;
      color: #1f304f;

      &:hover {
        background: #e7e7e7;
      }

      .partition {
        width: 100%;
        height: 100%;
        display: flex;

        div {
          width: 30%;
          height: 100%;
        }

        input {
          width: 70%;
          height: 100%;
          border: none;
          background: transparent;
          text-align: end;
          color: #1f304f;
          &::placeholder {
            font-weight: 550;
          }
        }
      }

      div {
        font-weight: 500;
        color: #1f304f;
      }

      img {
        width: 25px;
        height: 25px;
      }

      input {
        width: 100%;
        height: 100%;
        border: none;
        background: transparent;
        color: #1f304f;
        &::placeholder {
          font-weight: 550;
        }
      }

      .bgColorDiv {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);
        border: 0.5px solid #e5e5e5;
        width: 12%;
        height: 60%;
        border-radius: 5px;
      }
    }

    .each-action2 {
      display: flex;
      gap: 10px;
      align-items: center;
      border: 1px solid #e5e5e5;
      border-radius: 15px;
      cursor: pointer;
      margin-top: 1rem;
      transition: all ease 0.2s;
      color: #1f304f;

      &:hover {
        background: #e7e7e7;
      }

      input {
        width: 80%;
        height: 100%;
        border: none;
        padding: 2rem 1.5rem;
        background: transparent;
        &::placeholder {
          font-weight: 550;
        }
      }

      .currencyDiv {
        width: 20%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 0.5px solid #e5e5e5;
        font-weight: 700;
        color: #1f304f;
      }
    }

    .successMsg {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 1.5rem;
      text-align: center;
      color: #1f304f;
    }

    .coverPic-container {
      width: 100%;
      height: 19rem;
      position: relative;

      .coverPicDiv {
        width: 100%;
        height: 15rem;
        border-bottom: 0.5px solid #e5e5e5;
        border-top: 0.5px solid #e5e5e5;
        z-index: 1;
      }

      .logoDiv {
        width: 140px;
        height: 140px;
        position: absolute;
        left: 12.5rem;
        top: 11rem;
        z-index: 2;
        background: #ffffff;
        border-radius: 50%;
        border: 1px solid #e5e5e5;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .inputs-container {
      width: 100%;
      padding: 0 3rem;
      margin-top: 3rem;
    }

    .btnss-div {
      width: 100%;
      height: calc(100% - 10rem);
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .eachh-btnn {
        width: 100%;
        border-radius: 35px;
        border: 0.5px solid #e7e7e7;
        padding: 1rem 2rem;
        color: #1f304f;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          font-weight: 600;
        }
      }

      .eachh-btnn-selected {
        width: 100%;
        border-radius: 35px;
        border: 1px solid #1f304f;
        padding: 1rem 2rem;
        color: #1f304f;
        cursor: pointer;
        transition: all ease 0.2s;
        font-weight: 600;
      }
    }

    .leveltwo-steps {
      width: 100%;
      height: calc(100% - 5rem);
      display: flex;
      flex-direction: column;
      gap: 3rem;

      .each-leveltwo-field {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .input-boxx {
          border-radius: 35px;
          border: 0.5px solid #e7e7e7;
          padding: 1rem 2rem;

          input {
            border: none;
            outline: none;
          }
        }

        .input-boxxes {
          display: flex;
          gap: 1rem;
          flex-wrap: wrap;

          div {
            border-radius: 15px;
            border: 0.5px solid #e7e7e7;
            padding: 1rem 2rem;
            cursor: pointer;
            transition: all ease 0.2s;

            &:hover {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .stepBtns {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;

    div {
      background: #18191d;
      width: 100%;
      border-radius: 35px;
      color: #ffffff;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .stepBtnss {
    width: 100%;
    height: 3.5rem;
    display: flex;
    justify-content: space-between;
    padding: 0;

    div {
      width: 48%;
      border-radius: 35px;
      color: #ffffff;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .stepBtns1 {
    width: 100%;
    height: 15rem;
    // display: flex;
    // justify-content: space-between;
    padding: 0.5rem 0;

    div {
      background: #18191d;
      width: 100%;
      border-radius: 35px;
      color: #ffffff;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all ease 0.2s;
      margin-bottom: 1rem;

      &:hover {
        // transform: scale(1.05);
        font-size: 18px;
      }
    }
  }

  .successMsg {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;
    color: #1f304f;
  }
}


.optioncardWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.optionCardSmall {
  border-radius: 15px;
  border: 0.5px solid #e7e7e7;
  background: #fff;
  width: 83.52px;
  height: 55.991px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
}
.optionCardSmallSelected {
  border-radius: 15px;
  border: 0.5px solid #e7e7e7;
  background: linear-gradient(90deg, #47b4d5 0.02%, #29449d 119.26%);
  width: 83.52px;
  height: 55.991px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  color: #fff;
}
.optionCardFullWrapper {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // margin-top: 1rem;
}
.optionCardFull {
  border-radius: 35px;
  border: 0.5px solid #e7e7e7;
  background: #fff;
  width: 100%;
  height: 55.991px;
  display: flex;
  // justify-content: center;
  padding-left: 20px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1rem;
  text-transform: capitalize;
  &:hover {
    font-weight: 700;
  }
}
.optionCardFullSelected {
  border-radius: 35px;
  border: 0.5px solid #e7e7e7;
  background: linear-gradient(90deg, #47b4d5 0.02%, #29449d 119.26%);
  width: 100%;
  height: 55.991px;
  display: flex;
  // justify-content: center;
  padding-left: 20px;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  color: #fff;
  margin-bottom: 1rem;
  text-transform: capitalize;
}
