
.inputDivs {
    // line-height: 2;
    font-size: 1rem;
    // font-weight: 550;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    position: relative;
    margin-top: 2rem;
  
    // & > .heading {
    // }
  
    & > .inputHolder {
      position: relative;
  
      & > .editIconDiv {
        border: 0.5px solid #e7e7e7;
        position: absolute;
        right: -10px;
        top: -12px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border-radius: 50%;
        cursor: pointer;
      }
  
      & > .inputFields {
        color: #100F0D;
        font-weight: 550;
        outline: none;
        border: 0.5px solid #e5e5e5;
        border-radius: 10px;
        padding: 1rem 2rem;
        width: 100%;
        transition: all ease 0.4s;
  
        &::placeholder {
          color: rgb(52, 60, 91, 0.75);
        }
  
        &:hover {
          background-color: #7d7d7d27;
        }
      }
  
      & > .currencyDiv {
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translate(-50%, -50%);
      }
  
      & > .currencyDiv1 {
        position: absolute;
        top: 50%;
        right: -1rem;
        transform: translate(-50%, -50%);
        border: 0.5px solid #e5e5e5;
        width: 12%;
        height: 70%;
        border-radius: 5px;
      }
  
      & > .currencyDiv2 {
        position: absolute;
        top: 50%;
        right: -2rem;
        transform: translate(-50%, -50%);
        height: 70%;
        border-radius: 35px;
        background: #1f304f;
        color: #ffffff;
        display: flex;
        align-items: center;
        padding: 0 1.5rem;
        cursor: pointer;
        font-size: 0.9rem;
      }
    }
  
    & > .contentBox {
      display: flex;
      // justify-content: space-between;
      gap: 1rem;
  
      & > .valueBox {
        font-size: 1.3rem;
        border: 0.5px solid #e5e5e5;
        border-radius: 10px;
        padding: 1rem 2rem;
        width: 90%;
        font-weight: 600;
        display: flex;
        gap: 1rem;
        transition: all ease 0.4s;
  
        // &:hover {
        //   background-color: #7d7d7d27;
        // }
  
        & > img {
          height: 2rem;
          border-radius: 50%;
        }
  
        // & > div {
        //   width: inherit;
        // }
      }
  
      & > .dateBox {
        width: 100%;
        display: flex;
        justify-content: space-between;
  
        & > div {
          width: 31%;
          border: 0.5px solid #e5e5e5;
          border-radius: 10px;
          padding: 1rem 2rem;
          transition: all ease 0.4s;
  
          // &:hover {
          //   background-color: #7d7d7d27;
          // }
        }
      }
  
      & > .imageBox {
        width: 7rem;
        height: 7rem;
  
        & > img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: contain;
          transition: all ease-in 0.4s;
          
  
          // &:hover {
          //   scale: 1.2;
          // }
        }
      }
  
      & > :nth-child(2) {
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
  
        & > img {
          height: 40%;
          max-height: 1.7rem;
          object-fit: contain;
          cursor: pointer;
          transition: all ease-in 0.4s;
  
          &:hover {
            scale: 1.2;
          }
        }
      }
  
      & > .newDataAdd {
        display: block;
        border: 0.5px solid #e5e5e5;
        border-radius: 25px;
        padding: 1rem 3rem;
        // min-width: max-content;
        width: max-content;
        text-align: center;
        cursor: pointer;
        transition: all ease-in 0.4s;
        // font-weight: 800;
  
        &:hover {
          background: #7d7d7d27;
        }
      }
    }
  
    & > .newDataAdd {
      display: block;
      border: 0.5px solid #e5e5e5;
      border-radius: 25px;
      padding: 1rem 3rem;
      width: max-content;
      // width: 50%;
      text-align: center;
      cursor: pointer;
      transition: all ease-in 0.4s;
      // font-weight: 800;
  
      &:hover {
        background: #7d7d7d27;
      }
    }
  }

// 

.labelClass{
  font-size: 16px;
  font-weight: 400;
  color: #1F304F;
  padding-bottom: 20px;
}
.inputClass{
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  height: 54px;
  padding: 0px 15px;
  margin-bottom: 10px;
}
.textareaClass{
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  height: 171px !important;
  padding: 15px 15px;
  margin-bottom: 10px;
}
.imgContainer{
  display: flex;
  align-items: center;
  .logoText{
    padding-left: 20px;
  }
}
.submitBtn{
  // background: linear-gradient(89deg, #47b4d5 0%, #29449d 100%);
  background-color: #2c7cb2;
  color: white;
  height: 60px;
  border-radius: 35px;
  padding: 15px;
  text-align: center;
  font-weight: 700;
  margin-top: 50px;
}